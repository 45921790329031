.loading-wrapper {
  width: 100%;
  height: 100%;
  position: relative;

  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: white;
    z-index: 98;
    opacity: 0.5;
  }

  .spinner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    &__label {
      display: -webkit-box;
      font-size: 0.8em;
      text-align: center;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .disabled {
    opacity: 0.5;
  }
  .fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
}
