/* Setup Font */
@font-face {
  font-family: 'Exo2.0-Regular';
  src: url('../../fonts/Exo2.0-Regular.eot');
  src: local('☺'), url('../../fonts/Exo2.0-Regular.woff') format('woff'),
    url('../../fonts/Exo2.0-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Exo2.0-Light';
  src: url('../../fonts/Exo2.0-Light.eot');
  src: local('☺'), url('../../fonts/Exo2.0-Light.woff') format('woff'),
    url('../../fonts/Exo2.0-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OCRAStd';
  src: url('../../fonts/OCRAStd.eot');
  src: local('☺'), url('../../fonts/OCRAStd.woff') format('woff'),
    url('../../fonts/OCRAStd.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Play-Regular';
  src: url('../../fonts/Play-Regular.eot');
  src: local('☺'), url('../../fonts/Play-Regular.woff') format('woff'),
    url('../../fonts/Play-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
