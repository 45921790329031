/*-------------------------- Abstracts --------------------------*/
@import './abstracts/variables';
@import './abstracts/utils';
@import './abstracts/mixins.scss';
@import './abstracts/atomic';
@import './abstracts/fonts.scss';

/*-------------------------- Base --------------------------*/
@import './base/reset.scss';

/*-------------------------- Vendor --------------------------*/
@import './vendor/index.scss';

body {
  font-family: Play-Regular;
  color: $default-text-color;
  background: $bg-secondary;
}
