@mixin generate-utility($utility) {
  $values: map-get($utility, value);
  // if the values are list or string, convert it into a map

  @if type-of($values) == 'string' or type-of(nth($values, 1)) != 'list' {
    $values: zip($values, $values);
  }

  // multiple properties are possible
  $properties: map-get(
    $map: $utility,
    $key: property,
  );
  @if type-of($value: $properties) == 'string' {
    $properties: append(
      $list: (),
      $val: $properties,
    );
  }

  $class: map-get(
    $map: $utility,
    $key: class,
  );
  $important: map-get(
    $map: $utility,
    $key: important,
  );

  $class: if($class != null, $class, nth($properties, 1));
  // generate style
  @each $key, $value in $values {
    .#{$class + '-' + $key} {
      @each $property in $properties {
        #{$property}: #{$value} #{if($important == true, !important, null)};
      }
    }
  }
}

@mixin full-absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin full-fixed {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin fit-parent {
  width: 100%;
  height: 100%;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
