@import 'assets/styles/abstracts/variables';

.nav-bar {
  text-transform: uppercase;
  & > * {
    margin-left: 30px;
  }
  &__link {
    color: unset;
    &:hover {
      text-decoration: none;
    }

    &.active {
      color: $primary-color;
    }
  }
  &__icon {
    color: unset;
    &:hover {
      color: $primary-color;
    }
  }

  .drop-down-wrapper {
    position: relative;

    &:hover {
      .menu-wrapper {
        visibility: visible;
      }
    }
    .menu-wrapper {
      position: absolute;
      top: 20px;
      padding-top: 5px;
      right: 0;
      visibility: hidden;
    }
  }
  .drop-down {
    background: $bg-secondary;
    border: 0.5px solid #ebebeb87;
    cursor: default;
    font-size: 1rem;
    min-width: 150px;
    & > * {
      text-align: center;
      width: 100%;
      padding: 5px 10px;
      display: inline-block;
      cursor: pointer;
      white-space: nowrap;
      &:hover {
        background: white;
        a {
          display: inline-block;
          width: 100%;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
