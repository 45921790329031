.icon-btn {
  padding: 5px 10px;
  cursor: pointer;
  color: unset;
  width: fit-content;

  &--hover:hover {
    background-color: rgb(243, 242, 241);
  }
  & > * {
    margin-right: 7px;
    &:last-child {
      margin-right: 0;
    }
  }
}
