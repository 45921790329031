@import 'assets/styles/abstracts/mixins';

.confirm-dialog {
  &__content {
    text-align: left;
    background: #fff;
    border-radius: 0.25rem;
    .title {
      font-weight: 600;
      font-size: 16px;
    }
    .message {
      margin-top: 8px;
      font-size: 14px;
    }

    .actions {
      margin-top: 26px;
    }

    .icon {
      font-size: 22px;
      line-height: 1;
      &--warn,
      &--warning {
        color: var(--warning);
      }
      &--success {
        color: var(--success);
      }
      &--error {
        color: var(--danger);
      }
      &--info {
        color: var(--primary);
      }
      &--confirm {
        color: var(--primary);
      }
    }
  }
}
