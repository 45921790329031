// color
$primary-color: #60cdf6;
$default-primary-color: #007bff;
// text color
$default-text-color: #19233c;

// bg color
$bg-primary: rgb(62, 62, 62);
$bg-secondary: #f9f9fc;

// layer
