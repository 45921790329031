@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';

.header {
  border-bottom: 15px solid $primary-color;
  &__top-wrapper {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      right: 0;
      height: 4px;
      background: rgb(62, 62, 62);
    }
  }
  &__top {
    height: 72px;
  }
  &__bottom {
    padding: 30px 15px;
    background-color: $bg-primary;
    position: relative;
    img {
      position: absolute;
      top: 0;
      left: 15px;
      bottom: 0;
      height: 100%;
      width: auto;
    }
  }

  &__title {
    color: white;
    text-align: center;
    h3,
    p,
    a {
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    h3 {
      letter-spacing: 1.5px;
      max-width: 60vw;
    }
    a {
      color: white;
      text-decoration: underline;
    }
  }

  .logo {
    height: 100%;
    background-color: rgb(62, 62, 62);
    clip-path: polygon(0 0, 50% 0, 100% 100%, 0% 100%);
    position: relative;
    &__src {
      cursor: pointer;
    }
    a {
      max-width: 50%;
      height: 80%;
      img {
        max-height: 100%;
      }
    }
  }
}

.infinity-progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 2.5px;
  background: #fff;
  .bg {
    @include full-absolute;
    background-color: $primary-color;
    opacity: 0.5;
  }
  .loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: $primary-color;
    height: 100%;
    width: 0;
    animation: borealis 1s linear infinite;
  }
}

@keyframes borealis {
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
  }
  10% {
    left: 0%;
    right: 75%;
    width: 50%;
  }
  90% {
    right: 0%;
    left: 75%;
    width: 50%;
  }
  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }
}
